import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logOut } from "../session";
import * as Api from "../../apis";
import * as Twilio from "../../apis/twilio";
import * as Daily from "../../apis/dailyco";
import * as Type from "../../helpers/constants";

export const getChannelMessages = createAsyncThunk("chatroom/messages/get", async (channel) => {
  return Twilio.getChannelMessages(channel);
});

export const createTextMessage = createAsyncThunk("chatroom/messages/text/create", async (data) => {
  const { channel, text } = data;
  const val = {
    type: Type.ChatMessageType.TEXT,
    value: text
  };
  await Twilio.createMessage(channel, JSON.stringify(val));
  return Twilio.getChannelMessages(channel);
});

export const createImageMessage = createAsyncThunk("chatroom/messages/image/create", async (data) => {
  const { channel, img } = data;
  const formData = new FormData();
  formData.append("file", img);
  await Twilio.createMessage(channel, formData);
  return Twilio.getChannelMessages(channel);
});

export const createVideoMessage = createAsyncThunk("chatroom/messages/video/create", async (channel) => {
  const res = await Daily.createVideoRoomWithName(channel.uniqueName);
  if (res.url != null && res.token != null) {
    const url = `${res.url}?t=${res.token}`;
    const msg = {
      type: Type.ChatMessageType.VIDEO_CALL,
      value: { url }
    }
    await Twilio.createMessage(channel, JSON.stringify(msg));
  }
  return Twilio.getChannelMessages(channel);
});

export const getChannelUsers = createAsyncThunk("chatroom/users/get", async (channel) => {
  return Twilio.getChannelUsers(channel);
});

export const getChatrooms = createAsyncThunk("chatrooms/get", async (filter) => {
  const { ordering, payment_status } = filter;
  return Api.getChatrooms(ordering, payment_status);
});

export const getChatroom = createAsyncThunk("chatroom/get", async (chatroomId, { dispatch }) => {
  const payload = await Api.getChatroom(chatroomId);
  if (payload.customer) {
    dispatch(getPatientRecords(payload.customer.unique_id));
    if (!payload.is_close) {
      dispatch(getAppointmentRecords(chatroomId));
      dispatch(getEvaluationRecords(chatroomId));
      dispatch(getLabRecords(chatroomId));
      dispatch(getQuestionaireRecords(chatroomId));
      dispatch(getChatroomPatientThaiIdCard(chatroomId));
    }
  };
  return payload;
});

export const enterChatroom = createAsyncThunk("chatroom/enter", async (chatroomId) => {
  await Api.postEnterChatroom(chatroomId);
  return Api.getChatroom(chatroomId);
});

export const closeChatroom = createAsyncThunk("chatroom/close", async (data) => {
  const { chatroomId, payload } = data;
  const result = await Api.postCloseChatroom(chatroomId, payload);
  await Daily.deleteVideoRoom(chatroomId);
  return result;
});

export const updateChatroomSignSignature = createAsyncThunk("chatroom/signsignature/update", async (data) => {
  const { chatroomId, formData } = data;
  return Api.updateChatroomSignSignature(chatroomId, formData);
});

export const getChatroomSignSignature = createAsyncThunk("chatroom/signsignature/get", async (chatroomId) => {
  return Api.getChatroomSignSignature(chatroomId);
});

export const getPatientRecords = createAsyncThunk("chatroom/patientrecords/get", async (userId) => {
  return Api.getPatientRecords(userId);
});

export const getLabRecords = createAsyncThunk("chatroom/labrecords/get", async (chatroomId) => {
  return Api.getLabRecords(chatroomId);
});

export const getAppointmentRecords = createAsyncThunk("chatroom/appointmentrecords/get", async (chatroomId) => {
  return Api.getChatroomRecords(chatroomId);
});

export const getEvaluationRecords = createAsyncThunk("chatroom/evaluationrecords/get", async (chatroomId) => {
  return Api.getEvaluationRecords(chatroomId);
});

export const getQuestionaireRecords = createAsyncThunk("chatroom/questionairerecords/get", async (chatroomId) => {
  return Api.getQuestionaireRecords(chatroomId);
});

export const getChatroomPatientThaiIdCard = createAsyncThunk("chatroom/patientthaiidcard/get", async (chatroomId) => {
  return Api.getChatroomPatientThaiIdCard(chatroomId);
});

const slice = createSlice({
  name: "doctor/chatrooms",
  initialState: {
    videoCallSrc: null,
    evaluationRecords: [],
    labRecords: [],
    questionaireRecords: [],
    appointmentRecords: [],
    patientRecords: [],
    patientThaiIdCard: null,
    customer_medical_certificate_data: null,
    messages: [],
    userDescriptorById: {},
    signSignatureImage: null,
    byId: {},
  },
  reducers: {
    openVideoCall(state, action) {
      state.videoCallSrc = action.payload;
    },
    closeVideoCall(state) {
      state.videoCallSrc = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChannelMessages.fulfilled, (state, action) => {
      state.messages = action.payload.items;
    });
    builder.addCase(getChannelUsers.fulfilled, (state, action) => {
      for (const item of action.payload) {
        state.userDescriptorById[item.id] = item;
      }
    });
    builder.addCase(getChatrooms.fulfilled, (state, action) => {
      for (const item of action.payload.results) {
        state.byId[item.id] = item;
      }
    });
    builder.addCase(getChatroom.pending, (state) => {
      state.messages = [];
      state.evaluationRecords = [];
      state.labRecords = [];
      state.questionaireRecords = [];
      state.appointmentRecords = [];
      state.patientRecords = [];
      state.patientThaiIdCard = null;
      state.signSignatureImage = null;
      state.customer_medical_certificate_data = null;
    });
    builder.addCase(getChatroom.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload;
    });
    builder.addCase(enterChatroom.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload;
    });
    builder.addCase(getQuestionaireRecords.fulfilled, (state, action) => {
      state.questionaireRecords = action.payload.results;
    });
    builder.addCase(getEvaluationRecords.fulfilled, (state, action) => {
      state.evaluationRecords = action.payload.results;
    });
    builder.addCase(getAppointmentRecords.fulfilled, (state, action) => {
      state.appointmentRecords = action.payload.results;
    });
    builder.addCase(getLabRecords.fulfilled, (state, action) => {
      state.labRecords = action.payload.results;
    });
    builder.addCase(getPatientRecords.fulfilled, (state, action) => {
      state.patientRecords = action.payload;
    });
    builder.addCase(getChatroomPatientThaiIdCard.fulfilled, (state, action) => {
      state.patientThaiIdCard = action.payload;
    });
    builder.addCase(closeChatroom.fulfilled, (state, action) => {
      state.byId[action.payload.id] = action.payload;
    });
    builder.addCase(getChatroomSignSignature.fulfilled, (state, action) => {
      state.signSignatureImage = action.payload.sign_signature;
    });
    builder.addCase(updateChatroomSignSignature.fulfilled, (state, action) => {
      state.signSignatureImage = action.payload.sign_signature;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state = {
        videoCallSrc: null,
        evaluationRecords: [],
        labRecords: [],
        questionaireRecords: [],
        appointmentRecords: [],
        patientRecords: [],
        messages: [],
        patientThaiIdCard: null,
        signSignatureImage: null,
        customer_medical_certificate_data: null,
        userDescriptorById: {},
        byId: {},
      };
    });
  },
});

const { actions, reducer } = slice;

export const { openVideoCall, closeVideoCall } = actions;

export const getAllChatRoomsSelector = (state) => Object.keys(state.chatrooms.byId).map(id => state.chatrooms.byId[id]);

export const getChatroomById = (state, id) => state.chatrooms.byId[id];

export default reducer;
