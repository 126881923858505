import * as Type from "../helpers/constants";

export const getMessageBody = (message) => {
  let msg;
  if (message.type === Type.ChatMessageType.TEXT) { // Twilio default type
    try {
      msg = JSON.parse(message.body);
    } catch (e) {
      if (typeof message.body === "string") {
        msg = { type: Type.ChatMessageType.TEXT, value: message.body };
      } else {
        msg = { type: Type.ChatMessageType.TEXT, value: "" };
      }
    }
  } else if (message.type === Type.ChatMessageType.MEDIA) { // Twilio type for images
    msg = { type: Type.ChatMessageType.MEDIA, value: message.media };
  }
  return msg;
}
