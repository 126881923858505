import moment from "moment";
import "moment/locale/th";
moment.locale("th");

export const DAYS = ["จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์", "อาทิตย์"];

export const MONTHS = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

export const validateSlotTime = (t) => {
  if (!t) return false;
  return !!t.match(/[0-2][0-9]:[0-5][0-9]/);
}

export const getDate = (date) => {
  return (date ? new Date(date) : new Date()).toISOString().slice(0, 10);
};

export const deliveryTomorrow = () => {
  let daysToAdd = 0;
  if (new Date().getHours() >= 19) daysToAdd = 1;
  return moment().add(daysToAdd, "days").set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate();
}

export const renderChatTime = (date) => {
  return moment(date).format("LT");
}

export const renderChatDate = (date) => {
  return moment(date).format("Do MMM");
}

export const isSameDate = (d1, d2) => {
  return moment(d1).format('L') === moment(d2).format('L')
}

export const parseAppointmentDateTime = (date, time) => {
  return new Date(`${date}T${time}+07:00`);
}

export const renderAppointmentDateTime = (date) => {
  return moment(date).format("lll");
}

export const renderRelativeDateTime = (date) => {
  return moment(date).fromNow();
}

export const dateFromTime = (time) => {
  return new Date(`${getDate()}T${time}+07:00`);
}

export function formatCalendarDate(date) {
  if (!date) return "";
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

export const validateStartAndEndTime = (start, end) => {
  if (!end || !start) return false;
  return start < end; // "10:00" < "11:00" is valid
}

export const isTimeOverlap = (startTime1, endTime1, startTime2, endTime2) => {
  return ((startTime1 <= startTime2 && startTime2 <= endTime1) || (startTime1 <= endTime2 && endTime1 >= startTime2));
}

export const validateTimeOverlapping = (timeRanges) => {
  const len = timeRanges.length;
  for (let i = 0; i < len - 1; i++) {
    const { start_time: start1, end_time: end1 } = timeRanges[i];
    for (let j = i + 1; j < len; j++) {
      const { start_time: start2, end_time: end2 } = timeRanges[j];
      // Check if the time ranges overlap
      if (isTimeOverlap(start1, end1, start2, end2)) {
        return false; // Overlap found
      }
    }
  }
  return true; // No overlap found
}

export const isStartAndEndTimeOverlapping = (arr) => {
  if (!Array.isArray(arr) || arr.length < 2) {
    throw new Error("Input must be an array of at least two objects");
  }
  
  // Check that each object in the input array has valid start and end properties
  arr.forEach((range) => {
    if (typeof range.start_time !== "string" || typeof range.end_time !== "string" ||
        !/^\d{2}:\d{2}$/.test(range.start_time) || !/^\d{2}:\d{2}$/.test(range.end_time)) {
      throw new Error("Invalid time range object");
    }
  });

  // Sort the array by start time
  arr.sort((a, b) => (a.start_time > b.start_time) ? 1 : -1);

  // Compare each adjacent pair of time ranges for overlap
  for (let i = 0; i < arr.length - 1; i++) {
    const currentEnd = arr[i].end_time;
    const nextStart = arr[i + 1].start_time;
    if (currentEnd >= nextStart) {
      return true; // overlapping ranges found
    }
  }

  return false; // no overlapping ranges found
}