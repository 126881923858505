import axios from "axios";
import qs from "qs";

class RequestHelper {

  authKey = "token";
  accessToken;

  getLineToken() {
    if (localStorage) {
      return localStorage.getItem("line_token");
    }
    return "";
  }

  _setLineToken(token) {
    if (localStorage) {
      if (token) {
        localStorage.setItem("line_token", token);
      } else {
        localStorage.removeItem("line_token");
      }
    }
  }

  getFirebaseToken() {
    if (localStorage) {
      return localStorage.getItem("firebase_token");
    }
    return "";
  }

  _setFirebaseToken(token) {
    if (localStorage) {
      if (token) {
        localStorage.setItem("firebase_token", token);
      } else {
        localStorage.removeItem("firebase_token");
      }
    }
  }

  getToken() {
    if (localStorage) {
      return localStorage.getItem("token");
    }
    return "";
  }

  _setToken(token) {
    this.accessToken = token;
    if (localStorage) {
      if (token) {
        localStorage.setItem("token", token);
      } else {
        localStorage.removeItem("token");
      }
    }
  }

  request(method = "get", url, data, header, version="v1") {
    const headers = header || {
      // "Content-Type": "application/json",
    };
    const token = this.accessToken || this.getToken();
    if (token) headers["Authorization"] = `${this.authKey} ${token}`;

    if (url[0] === "/") {
      url = url.slice(1);
    }

    return axios({
      url: `${process.env.REACT_APP_API_URL}${version}/${url}`,
      method,
      data,
      headers,
    }).then(response => {
      return response.data.result || response.data.results || response.data;
    }).catch(error => {
      return Promise.reject(error.response.data || error.response.data.message);
    });
  }

  post(url, payload) {
    return this.request("post", url, payload);
  }

  postMultipart(url, formData) {
    const header = {
      'Content-Type': 'multipart/form-data',
    }
    return this.request("post", url, formData, header);
  }

  post_v2(url, payload) {
    return this.request("post", url, payload, "", "v2");
  }

  patch(url, payload) {
    return this.request("patch", url, payload);
  }

  get(url) {
    return this.request("get", url);
  }

  get_v2(url) {
    return this.request("get", url, "", "", "v2");
  }

  logInLine(accessToken, options) {
    const payload = {
      access_token: accessToken,
      ...options
    };
    this._setLineToken(accessToken);
    return this._logIn("line", qs.stringify(payload));
  }

  logInTwilio() {
    return this.post("auth/twilio_authentication/");
  }

  _logIn(method = "", payload) {
    return this.post(`auth/${!!method ? `${method}/` : method}login/`, payload)
      .then(async (response) => {
        this._setToken(response.key);
        const user = await this.me();
        return user
      }).catch(error => {
        this._setToken();
        return Promise.reject(error.message);
      })
  }

  logOut() {
    this._setToken();
    this._setLineToken();
    this._setFirebaseToken();
    return Promise.resolve();
  }

  me() {
    return this.get("auth/me/");
  }

  consultantMe() {
    return this.get("auth/me/consultant/");
  }

  logInFirebase(accessToken) {
    const payload = {
      access_token: accessToken,
    };
    this._setFirebaseToken(accessToken);
    return this._logIn("firebase", qs.stringify(payload));
  }

  onesignalMe(playerId) {
    const payload = {
      player_id: playerId,
    };
    return this.post("auth/me/onesignal/", payload);
  }
}

export default new RequestHelper();
