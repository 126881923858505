/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { Modal, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { patchConsultantOnline } from "../../../actions/doctor/online";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import * as Api from "../../../apis";
import Joyride from 'react-joyride';
import AccessControl from "../AccessControl";

const tutorialSteps = [{
  target: ".avaliable-now",
  content: (
    <div>
      เปิดสถานะออนไลน์ เพื่อให้ผู้ป่วยสามารถปรึกษาคุณได้ทันที โดยไม่ต้องนัดหมายล่วงหน้า
      <br />
      <a href="https://moronline.freshdesk.com/support/solutions/articles/72000599142-feature-online-now" target="_blank">อ่านเพิ่มเติม</a>
    </div>
  ),
  disableOverlayClose: true,
  disableBeacon: true,
  hideFooter: true,
  title: "Online Now",
}];

export default () => {
  const dispatch = useDispatch();
  const { consultant } = useSelector(state => state.session);
  const online = useSelector((state) => state.online);
  const [ avaliable, setAvaliable ] = React.useState(consultant ? consultant.is_online : false); //
  const [ color, setColor ] = React.useState(consultant.is_online ? "#44c10e" : "#bfbfbf"); //
  const [ extraTime, setExtraTime ] = React.useState(false);
  const [ run, setRun ] = React.useState(Api.getTutorial() ? false : true);

  React.useEffect(() => {
    let offtime = 0;
    if (window.onlineTimeout1) {
      clearTimeout(window.onlineTimeout1);
    }
    if (window.onlineTimeout2) {
      clearTimeout(window.onlineTimeout2);
    }
    if (online.online) {
      if (online.online.is_online) {
        offtime = online.online.auto_offline_datetime;
      }
      if (typeof offtime !== "number") {
        timeout(offtime);
      }
    }
  }, [online])

  const onChangeStatus = async (value = false) => {
    const status = value ? (extraTime ? true : false) : (avaliable ? false : true)
    try {
      const res = await dispatch(patchConsultantOnline({is_online: status})).unwrap();
      setAvaliable(res.is_online);
      res.is_online ? setColor("#44c10e") : setColor("#bfbfbf");
    } catch (e) {}
  };

  const confirm = (setExtraTime) => {
    const modal = Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "ต่อเวลาสถานะออนไลน์หรือไม่",
      okText: "ต้องการต่อเวลา",
      cancelText: "ไม่ต้องการ",
      onOk() {
        setExtraTime(true);
      },
      onCancel() {
        setExtraTime(false);
      },
      maskClosable: false,
    });
    return modal;
  };

  const timeout = async (time) => {
    const offlineTime = new Date(time);
    const now = new Date();
    const difference = offlineTime.getTime() - now.getTime();
    const timeRecheck = difference - (10 * 60000); // Recheck modal should appear 10 minutes before times off.

    if (difference > 0) {
      let modal = "";
      window.onlineTimeout1 = setTimeout(() => {
        modal = confirm(() => setExtraTime());
      }, timeRecheck); // 10 นาทีให้ขึ้นแจ้งเตือน yes ครบแล้วถึงกดต่อ no ครบแล้วไม่ต้องทำอะไร ไม่ตอบสนองก็ปล่อยไป แต่ขึ้นมาแบบบล็อคฟีเจอร์อื่น
      window.onlineTimeout2 = setTimeout(() => {
        onChangeStatus(true);
        modal.destroy();
      }, difference); // ทำต่อ ไม่ว่าใช่หรือไม่ให้ไปเรียกเอพีไออีกรอบ
    }
  }

  const handleCallback = (data) => {
    const { action } = data;
    if (action === "close") {
      Api.setTutorial(true);
      setRun(false);
    }
  }

  return (
    <>
      <Joyride
        steps={tutorialSteps}
        run={run}
        callback={handleCallback}
      />
      <AccessControl allowedPermissions={["doctor"]}>
        <Switch
          className="avaliable-now"
          style={{ backgroundColor: color, borderColor: color }}
          checkedChildren="online"
          unCheckedChildren="offline"
          checked={avaliable}
          onClick={() => onChangeStatus()}
        />
      </AccessControl>
    </>
  )
}