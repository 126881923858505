import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logInTwilio } from "../actions/session";
import * as Twilio from "../apis/twilio";

export default () => {
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);

  useEffect(() => {
    if (!session.twilio) {
      dispatch(logInTwilio());
    }
  }, []);

  return session.twilio ? Twilio : null;
}
