import { configureStore } from "@reduxjs/toolkit";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import logger from "redux-logger";
import session from "../actions/session";
import chatrooms from "../actions/doctor/chatrooms";
import calendar from "../actions/doctor/calendar";
import api from "../actions/api";
import notification from "../actions/notification";
import online from "../actions/doctor/online";

const enhancers = [];
if (
  process.env.NODE_ENV === "production" &&
  !!process.env.REACT_APP_SENTRY &&
  window != null
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    normalizeDepth: 10, // Or however deep you want your state context to be.
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
  enhancers.push(Sentry.createReduxEnhancer({
    // Optionally pass options listed below
  }));
}

export default configureStore({
  reducer: {
    session,
    chatrooms,
    calendar,
    api,
    notification,
    online,
  },
  middleware: (getDefaultMiddleware) => {
    let res = getDefaultMiddleware({
      serializableCheck: false
    });
    if (process.env.REACT_APP_API_URL === "https://staging-service.moronline.me/api/v1/") {
      res = res.concat(logger);
    }
    return res;
  },
  enhancers: enhancers
});
