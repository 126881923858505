import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "../../apis";

export const patchConsultantOnline = createAsyncThunk("online/patch", async (payload) => {
  return Api.patchConsultantOnline(payload);
});

export const slice = createSlice({
  name: "doctor/online",
  initialState: {
    online: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(patchConsultantOnline.fulfilled, (state, action) => {
      state.online = action.payload;
    });
  },
});

export default slice.reducer;
