/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Skeleton, Empty } from "antd";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "react-image-lightbox/style.css";

/**
 * Components
 */
import Main from "./components/layout/Main";
import DetectLocationChange from "./components/general/DetectLocationChange";
import OneSignal from "./components/general/OneSignal";
import AccessControl from "./components/general/AccessControl";
import NotificationTwilio from "./components/general/NotificationTwilio";
import VideoCallWindow from "./components/doctor/VideoCallWindow";

/**
 * Pages
 */
const Home = lazy(() => import("./pages/Home"));
const SignUp = lazy(() => import("./pages/SignUp"));
const SignIn = lazy(() => import("./pages/SignIn"));
const LineLoginCallback = lazy(() => import("./pages/LineLoginCallback"));
const LogOut = lazy(() => import("./pages/LogOut"));
const Page403 = lazy(() => import("./pages/403"));
const Page404 = lazy(() => import("./pages/404"));
const DoctorCalendar = lazy(() => import("./pages/doctor/Calendar"));
const DoctorDrugList = lazy(() => import("./pages/doctor/DrugList"));
const DoctorLabList = lazy(() => import("./pages/doctor/LabList"));
const DoctorChatroomList = lazy(() => import("./pages/doctor/ChatroomList"));
const DoctorChatroom = lazy(() => import("./pages/doctor/Chatroom"));
const DoctorCloseChatroom = lazy(() => import("./pages/doctor/CloseChatroom"));
const DoctorSummary = lazy(() => import("./pages/doctor/Summary"));
const Chatroom = lazy(() => import("./components/doctor/Chatroom"));


const SuspenseWithFallback = ({ children }) => <Suspense fallback={<Skeleton />}>{children}</Suspense>;
const EmptyPlaceholder = () => <Empty description={false} />;

function App() {

  return (
    <div className="App">
      <NotificationTwilio />
      <VideoCallWindow />
      <OneSignal />
      <DetectLocationChange />
      <SuspenseWithFallback>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/403" component={Page403} />
          <Route path="/404" component={Page404} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/logOut" component={LogOut} />
          <Route path="/lineLoginCallback" component={LineLoginCallback} />
          <Main>
            <SuspenseWithFallback>

              <Route path="/hospital" render={() =>
                <AccessControl
                  allowedPermissions={["hospital"]}
                  renderNoAccess={() => <Redirect to="/403" />}
                >
                  <Route path="/hospital/overview" component={Page403} />
                  <Route path="/hospital/manageDoctors" component={Page403} />
                  <Route path="/hospital/manageDrugs" component={Page403} />
                  <Route path="/hospital/patients" component={Page403} />
                </AccessControl>
              } />

              <Route path="/doctor" render={() =>
                <AccessControl
                  allowedPermissions={["doctor"]}
                  renderNoAccess={() => <Redirect to="/403" />}
                >
                  <Route path="/doctor/drugList" component={DoctorDrugList} />
                  <Route path="/doctor/labList" component={DoctorLabList} />
                  <Route path="/doctor/calendar" component={DoctorCalendar} />
                  <Route path="/doctor/chatroom"
                    render={() => (
                      <DoctorChatroomList>
                        <SuspenseWithFallback>
                          <Route exact path="/doctor/chatroom" component={EmptyPlaceholder} />
                          <Route
                            exact
                            path={["/doctor/chatroom/:id", "/doctor/chatroom/summary/:id", "/doctor/chatroom/close/:id"]}
                            render={() =>
                              <DoctorChatroom>
                                <SuspenseWithFallback>
                                  <Route exact path="/doctor/chatroom/:id" component={Chatroom} />
                                  <Route path="/doctor/chatroom/summary/:id" component={DoctorSummary} />
                                  <Route path="/doctor/chatroom/close/:id" component={DoctorCloseChatroom} />
                                </SuspenseWithFallback>
                              </DoctorChatroom>
                            }
                          />
                        </SuspenseWithFallback>
                      </DoctorChatroomList>
                    )}
                  />
                </AccessControl>
              } />

            </SuspenseWithFallback>
          </Main>
        </Switch>
      </SuspenseWithFallback>
    </div>
  );
}

export default App;
