//https://github.com/duhseekoh/demo-access-control-in-react/blob/master/example-apps/v3-access-control-logic/AccessControl.js
import { useSelector } from "react-redux";

const isPermitted = (userPermissions, allowedPermissions, oneperm) => {
  //Permit when no allowedPermissions specify
  if (allowedPermissions.length === 0) return true;

  //Permit when userPermissions match with allowedPermissions (oneperm means atleast one permission is required)
  return allowedPermissions[oneperm ? "some" : "every"](permission =>
    userPermissions.includes(permission)
  );
}

const AccessControl = ({
  oneperm = false,
  allowedPermissions = [],
  children = null,
  renderNoAccess = () => null,
}) => {
  const { user } = useSelector(state => state.session);
  const userPermissions = [];
  if (user) {
    userPermissions.push(user.user_type);
  }
  return isPermitted(userPermissions, allowedPermissions, oneperm)
    ? children
    : renderNoAccess();
}


AccessControl.defaultProps = {
  oneperm: false,
  allowedPermissions: [],
  renderNoAccess: () => null,
};

export default AccessControl;
