/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { logInLiff, getMe, getConsultantMe } from "./actions/session";
import { getUrlParams } from './helpers/location';
import App from "./App";
import store from "./store";

const render = () => {
  ReactDOM.render(
    <>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </>,
    document.getElementById("root"),
  );
}

const query = getUrlParams(window.document.location.search)

console.log(query);

if (!!query.debug) {
  const VConsole = require('vconsole');
  new VConsole();
}

if (!!query.liffId) {
  // Line LIFF
  store.dispatch(logInLiff(query.liffId))
    .finally(() => {
      render();
    })
} else {
  store.dispatch(getMe())
    .then(() => store.dispatch(getConsultantMe()))
    .finally(() => {
      render();
    })
}