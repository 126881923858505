import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "../apis";
import * as Twilio from "../apis/twilio";
import * as Line from "../apis/line";
import * as Firebase from "../apis/firebase";
import * as Liff from '../apis/liff';

export const logOut = createAsyncThunk("logout", async () => {
  await Twilio.logOut();
  return Api.logOut();
});

export const getMe = createAsyncThunk("me/get", async () => {
  return Api.getMe();
});

export const getConsultantMe = createAsyncThunk("me/consultant/get", async () => {
  return Api.getConsultantMe();
});

export const logInLiff = createAsyncThunk("liff/login", async (liffId, { dispatch }) => {
  let res = await Liff.initLiff(liffId)
  res = await Api.logInLine(res.accessToken);
  window.setUserId(res.unique_id);
  await dispatch(getMe());
  await dispatch(getConsultantMe());
  return res;
});

export const logInLine = createAsyncThunk("line/login", async (code, { dispatch }) => {
  let res = await Line.postLineToken(code);
  res = await Api.logInLine(res.access_token);
  window.setUserId(res.unique_id);
  await dispatch(getMe());
  await dispatch(getConsultantMe());
  return res;
});

export const logInTwilio = createAsyncThunk("twilio/login", async () => {
  return Twilio.init();
});

export const logInFirebase = createAsyncThunk("firebase/login", async (payload, { dispatch }) => {
  const { email, password } = payload;
  let res = await Firebase.login(email, password);
  res = await Api.logInFirebase(res.user.accessToken);
  window.setUserId(res.unique_id);
  await dispatch(getMe());
  await dispatch(getConsultantMe());
  return res;
});

export const postOnesignalMe = createAsyncThunk("me/onesignal", async (playerId) => {
  return Api.postOnesignal(playerId);
});

export const slice = createSlice({
  name: "session",
  initialState: {
    user: null,
    twilio: null,
    line: Api.getLineToken(),
    isReady: false,
    consultant: null,
    firebase: Api.getFirebaseToken(),
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isReady = true;
    });
    builder.addCase(logInLiff.fulfilled, (state, action) => {
      state.line = Api.getLineToken();
      state.user = action.payload;
      state.isReady = true;
    });
    builder.addCase(logInLine.fulfilled, (state, action) => {
      state.line = Api.getLineToken();
      state.user = action.payload;
      state.isReady = true;
    });
    builder.addCase(logInTwilio.fulfilled, (state, action) => {
      state.twilio = action.payload;
    });
    builder.addCase(getConsultantMe.fulfilled, (state, action) => {
      state.consultant = action.payload;
    });
    builder.addCase(logInFirebase.fulfilled, (state, action) => {
      state.firebase = Api.getFirebaseToken();
      state.user = action.payload;
      state.isReady = true;
    });
    builder.addCase(logOut.fulfilled, (state, action) => {
      state.twilio = null;
      state.isReady = false;
      state.user = null;
      state.firebase = null;
      state.line = null;
      state.consultant = null;
    })
  },
});

export default slice.reducer;

// use state
export const getUser = (state) => state.session.user;
