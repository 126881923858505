import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Twilio from "../apis/twilio";

export const getNotificationChannel = createAsyncThunk("notification/channel/get", async (sid, { dispatch }) => {
  const ch = await Twilio.getChannel(sid);
  ch.on('messageAdded', (message) => {
    dispatch(addMessage(message));
    dispatch(getUnreadCount(ch));
  });
  return ch;
});

export const clearUnread = createAsyncThunk("notification/unread/clear", async (channel, { dispatch }) => {
  await Twilio.setMessagesRead(channel);
  dispatch(getUnreadCount(channel));
  return channel;
});

export const getUnreadCount = createAsyncThunk("notification/unread/get", async (channel) => {
  return Twilio.getUnreadMessagesCount(channel);
});

export const getMessages = createAsyncThunk("notification/messages/get", async (channel) => {
  const result = await Twilio.getChannelMessages(channel);
  return result.items;
});

export const slice = createSlice({
  name: "notification",
  initialState: {
    channel: null,
    count: 0,
    messages: [],
  },
  reducers: {
    addMessage(state, action) {
      state.messages.splice(0, 0, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationChannel.fulfilled, (state, action) => {
      state.channel = action.payload;
    });
    builder.addCase(getUnreadCount.fulfilled, (state, action) => {
      state.count = action.payload;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      let res = action.payload;
      if (Array.isArray(res)) {
        res = res.reverse();
      }
      state.messages = res;
    });
  },
});


const { actions, reducer } = slice;

export const { addMessage } = actions;

export default reducer;

// use state
export const getUser = (state) => state.session.user;
