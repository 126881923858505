import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logOut } from "../session";
import * as Api from "../../apis";

export const getRepeatTimeSlot = createAsyncThunk("timeslot/get", async () => {
  return Api.getRepeatTimeSlot();
});

export const postRepeatTimeSlot = createAsyncThunk("timeslot/post", async (payload) => {
  return Api.postRepeatTimeSlot(payload);
});

export const slice = createSlice({
  name: "doctor/calendar",
  initialState: {
    slot: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRepeatTimeSlot.fulfilled, (state, action) => {
      state.slot = action.payload;
    });
    builder.addCase(postRepeatTimeSlot.fulfilled, (state, action) => {
      state.slot = action.payload;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state = {
        slot: null,
      }
    });
  },
});

export default slice.reducer;
