import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import * as Analytics from "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const app = initializeApp(firebaseConfig); // export

const auth = getAuth(app);

const analytics = Analytics.getAnalytics(app);

export const logEvent = (name, params) => {
  if (!name) {
    return;
  }
  if (!params) params = {};
  params.origin = window.location.origin;
  params.pathname = window.location.pathname;
  Analytics.logEvent(analytics, name, params);
}

export const setUserId = (id) => {
  if (!id) {
    return;
  }
  Analytics.setUserId(analytics, id);
}

export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}

if (window != null) {
  window.logEvent = logEvent;
  window.setUserId = setUserId;
}
