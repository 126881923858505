import RequestHelper from "./requestHelper";

const encode = object => new URLSearchParams(Object.keys(object).reduce((prev, key) => !object[key] ? prev : { ...prev, [key]: object[key] }, {})).toString()

export const getMe = () => RequestHelper.me();

export const getConsultantMe = () => RequestHelper.consultantMe();

export const logOut = () => RequestHelper.logOut();

export const logInLine = (accessToken, options) => RequestHelper.logInLine(accessToken, options);

export const logInFirebase = (accessToken) => RequestHelper.logInFirebase(accessToken);

export const getLineToken = () => RequestHelper.getLineToken();

export const getFirebaseToken = () => RequestHelper.getFirebaseToken();

export const postOnesignal = (playerId) => RequestHelper.onesignalMe(playerId);

export const logInTwilio = () => RequestHelper.logInTwilio();

export const getChatrooms = (ordering, payment_status) =>
  RequestHelper.get(`chatrooms/staff/?${encode({
    ordering,
    payment_status
  })}`);

export const getChatroom = (id) => RequestHelper.get(`chatrooms/staff/${id}/`);

export const postEnterChatroom = (id) => RequestHelper.post(`chatrooms/staff/${id}/enterroom/`);

export const postCloseChatroom = (id, payload) => RequestHelper.post(`chatrooms/staff/${id}/close/`, payload);

export const getEvaluationRecords = (id) => RequestHelper.get(`chatrooms/staff/${id}/history/evaluations/`);

export const getChatroomPatientThaiIdCard = (id) => RequestHelper.get(`chatrooms/staff/${id}/history/patient/thaiidcard/`);

export const getQuestionaireRecords = (id) => RequestHelper.get(`chatrooms/staff/${id}/history/questionnaires/`);

export const getLabRecords = (id) => RequestHelper.get(`chatrooms/staff/${id}/history/lab/orders/`);

export const getChatroomRecords = (id) => RequestHelper.get(`chatrooms/staff/${id}/history/chatrooms/`);

export const getPatientRecords = (id) => RequestHelper.get(`patientrecord/patients/${id}/`);

export const getDiseaseList = (search) => RequestHelper.get(`patientrecord/disease/?limit=10&offset=0&search=${search}`);

export const getChatroomSignSignature = (id) => RequestHelper.get(`chatrooms/staff/${id}/signsignature/`);

export const updateChatroomSignSignature = (id, formData) => RequestHelper.postMultipart(`chatrooms/staff/${id}/signsignature/`, formData);

export const getMenusWithLimit = (limit) => (search, categoryId, page, lat, lng, filter) =>
  RequestHelper.get(
    `product/?${encode({
      limit,
      search,
      offset: page ? (page - 1) * limit : 0,
      categories__id: categoryId ? categoryId : undefined,
      lat,
      lng,
      ...filter
    })}`
  );

export const getMenusForDoctorWithLimit = limit => (search, chatroom) =>
  RequestHelper.get(
    `chatrooms/staff/${chatroom}/products/?${encode({
      limit,
      search,
    })}`
  );

export const getPrescriptionResources = () => RequestHelper.get(`product/prescriptionresource/`);

export const postRepeatTimeSlot = (payload) => RequestHelper.post(`appointments/repeat-time-slots/`, payload);

export const getRepeatTimeSlot = () => RequestHelper.get(`appointments/repeat-time-slots/me/`);

export const patchConsultantOnline = (payload) => RequestHelper.patch(`/auth/me/consultant/onlinestatus/`, payload);

export const setTutorial = (val) => {
  if (localStorage) {
    localStorage.setItem("tutorial", val);
  }
}

export const getTutorial = () => {
  if (localStorage) {
    return localStorage.getItem("tutorial");
  }
  return "";
}

export const getLabListStaff = () => RequestHelper.get('lab/packages/staff/?package_type=lab');

export const checkConsent = (consent_name) => RequestHelper.get_v2(`consents/currently/check/?consent_name=${consent_name}`);

export const postAcceptConsent = (consentName) => RequestHelper.post_v2(`consents/currently/accept/`, { consent_name: consentName });