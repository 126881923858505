import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createLoadingSelector } from "../../../actions/api";
import { getMessageBody } from "../../../helpers/messages";
import { renderChatDate, renderChatTime } from "../../../helpers/date";
import { getMessages } from "../../../actions/notification";
import { List } from "antd";
import { BellOutlined } from "@ant-design/icons";
import LoaderView from "../LoaderView";
import style from "./style.module.css";

const MAP = {
  "me.moronline://chatroom": "/doctor/chatroom/{param}",
  "me.moronline://exit": "/",
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { channel, messages, count } = useSelector((state) => state.notification);
  const loading = useSelector(createLoadingSelector(["notification/messages/get"]));

  React.useEffect(() => {
    if (channel) {
      dispatch(getMessages(channel));
    }
  }, [channel]);

  const navigateTo = (to) => {
    const key = Object.keys(MAP).find(val => to.includes(val));
    let _to = MAP[key];

    if (!_to) {
      return;
    }

    if (_to.includes("{param}")) {
      const param = to.slice(to.lastIndexOf("/") + 1);
      _to = _to.replace("{param}", param);
    }

    history.push(_to);
  }


  return (
    <LoaderView loading={loading} empty={!loading && messages.length == 0}>
      <List className={style.root}>
        {
          messages.map((message, index) => {
            let msg = getMessageBody(message);

            if (msg.type !== "notification") {
              return <div key={index} />;
            }

            const url = msg.value.url;

            return (
              <List.Item
                key={index}
                onClick={() => navigateTo(url)}
              >
                <List.Item.Meta
                  avatar={<BellOutlined />}
                  title={`${(msg.value.title)} ${(msg.value.message)}`}
                  description={`${renderChatDate(message.dateCreated)} ${renderChatTime(message.dateCreated)}`}
                />
              </List.Item>
            )
          })
        }
      </List>
    </LoaderView>
  )
}
