import { useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { getNotificationChannel, getUnreadCount } from "../../../actions/notification";
import useTwilio from "../../../hooks/useTwilio";

export default () => {
  const Twilio = useTwilio();
  const dispatch = useDispatch();
  const {isReady, user} = useSelector(state => state.session);

  useEffect(() => {
    if (Twilio && user && isReady) {
      init();
    }
  }, [Twilio, user, isReady]);

  const init = async () => {
    const channel = await dispatch(getNotificationChannel(user.notifaction_channel_id)).unwrap();
    await dispatch(getUnreadCount(channel));
  }

  return null;
};

