import * as Api from "../apis";

var Twilio;
let client;
let TOKEN;

const addScript = (src, id) => {
  return new Promise((resolve, reject) => {
    const existing = document.getElementById(id);
    if (existing) {
      resolve();
    } else {
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        resolve();
      };
      document.body.appendChild(script);
    }
  });
};

export const logOut = () => {
  client.shutdown();
  window.client = client = null;
}

export const init = async () => {
  if (client) {
    return client.user;
  }

  await addScript("https://media.twiliocdn.com/sdk/js/conversations/releases/2.2.0/twilio-conversations.min.js", "twilio-script");
  const payload = await Api.logInTwilio();
  TOKEN = payload.token;
  Twilio = window.Twilio;

  client = new Twilio.Conversations.Client(TOKEN, { logLevel: "info" });
  window.client = client;
  
  client.on("tokenAboutToExpire", updateToken);
  client.on("tokenExpired", updateToken);

  function updateToken() {
    Api.logInTwilio().then(payload => {
      TOKEN = payload.token;
      client.updateToken(TOKEN);
    });
  }

  return client.user;
}

export const getClient = () => client;

export const getChannel = (sid) => {
  return client.getConversationBySid(sid);
}

export const getChannelByUniqueName = (name) => {
  return client.getConversationByUniqueName(name);
}

export const getChannelMessages = (activeChannel, count = 100) => {
  return activeChannel.getMessages(count);
}

export const getUnreadMessagesCount = (activeChannel) => {
  return activeChannel.getUnreadMessagesCount();
}

export const setMessagesRead = async (activeChannel) => {
  const index = activeChannel.lastMessage?.index;
  await activeChannel.updateLastReadMessageIndex(index);
  return activeChannel;
}

export const createMessage = async (activeChannel, message) => {
  await activeChannel.sendMessage(message);
  return activeChannel;
}

export const getChannelUsers = (channel) => {
  return channel.getMembers().then(members => {
    return Promise.all(members.map(member => member.getUserDescriptor()));
  })
}