export enum QuestionnaireItemInputType {
  TEXT = "text",
  NUMBER = "number",
  ONE_CHOICE = "one_choice",
  MULTIPLE_CHOICE = "multiple_choice",
};

export enum GenderType {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other"
};

export enum UserType {
  PHARMACIST = "pharmacist",
  DOCTOR = "doctor",
  NURSE = "nurse",
  PSYCHOLOGIST = "psychologist",
  OCCUPATIONAL_THERAPIST = "occupationaltherapist",
  PHYSICAL_THERAPIST = "physicaltherapist",
  OTHER = "otherpersonnel",
};

export enum BloodType {
  A = "A",
  B = "B",
  AB = "AB",
  O = "O"
};

export enum RelationType {
  SELF = "self",
  PARENT = "parent",
  CHILD = "child",
  OTHER = "other",
};

export enum ProductType {
  PRODUCT = 1,
  PACKAGE = 2,
};

export enum ChatMessageType {
  VIDEO_CALL = "videocall",
  TEXT = "text",
  CONSENT = "consent",
  FILE = "file",
  CATALOG = "catalog",
  MEDIA = "media",
  MEDICAL_RECORD = "medicalrecord",
  ASSESSMENT = "assessment",
};


export enum PaymentType {
  PROMPTPAY = "promptpay",
  DEFAULT_CREDIT_CARD = "default_credit_card",
  CASH = "cash",
}

export enum PaymentStatus {
  WAITING = "waiting_paid",
  PAID = "paid",
}

export enum AssessmentType {
  AI = "ai",
  Questionnaire = "questionnaire",
}

export const ASSESSMENT_MENU = [
  {
    title: "ประเมินโรคทั่วไป",
    image: "/img/menu/med.png",
    url: "/assessment/personalInfo/"
  },
  {
    title: "ประเมินสุขภาพจิต",
    image: "/img/menu/mental.png",
    url: "/menu/mental"
  },
  {
    title: "ประเมินพัฒนาการเด็ก",
    image: "/img/menu/child.png",
    url: "/menu/child"
  },
]