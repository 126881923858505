import { useEffect, useState } from "react";
import { useSelector, useDispatch} from "react-redux";
import { postOnesignalMe } from "../../../actions/session";
import OneSignal from "react-onesignal";

export default () => {
  const dispatch = useDispatch();
  const {isReady} = useSelector(state => state.session);
  const [ subscribed, setSubscribed ] = useState(false);

  useEffect(() => {
    init();
  }, [isReady]);

  const init = async () => {
    if (!isReady) {
      return;
    }

    let cfg;
    if (process.env.REACT_APP_ONESIGNAL_ID) {
      cfg = {
        appId: process.env.REACT_APP_ONESIGNAL_ID,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true
      };
    }
    if (process.env.REACT_APP_ONESIGNAL_SAFARI) {
      cfg.safari_web_id = process.env.REACT_APP_ONESIGNAL_SAFARI;
    }
    if (cfg) {
      OneSignal.init(cfg);
      try {
        OneSignal.on('subscriptionChange', function(isSubscribed) {
          setSubscribed(isSubscribed);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (subscribed) {
      OneSignal.getUserId().then((playerId) => {
        dispatch(postOnesignalMe(playerId));
      });
    }
  }, [subscribed])

  return null;
};

