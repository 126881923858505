/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

import {
  Row,
  Col,
  Badge,
  Button,
  Drawer,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearUnread } from "../../actions/notification";
import { LogoutOutlined } from "@ant-design/icons";
import NotificationList from "../general/NotificationList";
import DoctorOnlineSwitch from "../general/DoctorOnlineSwitch";

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({
  placement,
  onPress,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, twilio } = useSelector(state => state.session);
  const { channel, count } = useSelector((state) => state.notification);
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => window.scrollTo(0, 0));

  React.useEffect(() => {
    if (twilio && visible && channel) {
      dispatch(clearUnread(channel));
    }
  }, [twilio, visible]);

  const showDrawer = () => setVisible(true);
  const hideDrawer = () => setVisible(false);

  const clickLogOut = e => {
    e.preventDefault();
    const val = window.confirm("ท่านต้องการออกจากระบบ?");
    if (val) {
      history.push("/logOut");
    }
  }

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={12}>
        <div className="ant-page-header-heading">
          <span>
            {`สวัสดี! ${user?.fullname}`}
          </span>
        </div>
      </Col>
      <Col span={24} md={12}>
        <Space className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Button type="link" onClick={clickLogOut}>
            <LogoutOutlined />
          </Button>

          <Badge count={count} size="small">
            <a onClick={showDrawer}>
              {bell}
            </a>
          </Badge>

          <Drawer
            title="การแจ้งเตือน"
            onClose={hideDrawer}
            placement={placement}
            open={visible}
          >
            <NotificationList />
          </Drawer>

          <DoctorOnlineSwitch />
        </Space>
      </Col>
    </Row>
  );
}

export default Header;