import qs from "qs";

export const postLineToken = async (code) => {
  const payload = {
    code: code,
    grant_type: "authorization_code",
    redirect_uri: `${window.location.origin}/lineLoginCallback`,
    client_id: process.env.REACT_APP_LINE_CLIENT_ID,
    client_secret: process.env.REACT_APP_LINE_CLIENT_SECRET
  };
  try {
    let response = await fetch("https://api.line.me/oauth2/v2.1/token", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: qs.stringify(payload),
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export const validateLineToken = async (token) => {
  try {
    let response = await fetch("https://api.line.me/oauth2/v2.1/verify?access_token=" + token, {
      method: "GET",
    });
    return await response.json();
  } catch (e) {
    console.error(e);
    throw e;
  }
}
