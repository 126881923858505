import { combineReducers } from "redux";

const loading = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)\/(pending|fulfilled|rejected)/.exec(type);
  
  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state;  
  
  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [requestName]: requestState === "pending",
  };
};

export const error = (state = {}, action) => {
  const { type, message } = action;
  const matches = /(.*)\/(pending|rejected)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName]: requestState === "rejected" ? message : "",
  };
};

export default combineReducers({
  loading,
  error,
});

export const createErrorMessageSelector = (actions) => (state) => {
  return actions.map(action => state.api.error[action]);
};

export const createLoadingSelector = (actions) => (state) => {
  return actions.some(action => !!state.api.loading[action]);
};
