import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Rnd } from "react-rnd";
import { Button } from "antd";
import { closeVideoCall } from "../../../actions/doctor/chatrooms";
import style from "./style.module.css";

const defaultConfig = {
  width: 600,
  height: 450,
  x: 10,
  y: 10
};

export default () => {
  const [config, setConfig] = React.useState(defaultConfig);
  const dispatch = useDispatch();
  const chatrooms = useSelector(state => state.chatrooms);

  const onClose = () => {
    dispatch(closeVideoCall());
    setConfig(defaultConfig);
  }

  const { videoCallSrc } = chatrooms;
  if (!videoCallSrc) {
    return null;
  }

  return (
    <Rnd
      className={style.root}
      lockAspectRatio
      size={{ width: config.width, height: config.height }}
      onDragStop={(e) => {
        if (e.target.tagName === "BUTTON") {
          onClose();
        }
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        setConfig({
          ...config,
          width: ref.style.width,
          height: ref.style.height,
        });
      }}
    >
      <div className={style.body}>
        <div className={style.bar}>
          <button onClick={onClose}>X</button>
        </div>
        <Button className={style.close} type="primary" danger onClick={onClose}>End Call</Button>
        <iframe src={videoCallSrc} allow="camera;microphone"/>
      </div>
    </Rnd >
  )
}
