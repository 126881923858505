import { Skeleton, Empty } from "antd";

export default ({ loading, empty, emptyDescription = "ไม่พบรายการ", children }) => {
  if (empty) {
    return <Empty description={emptyDescription} />;
  }
  if (loading) {
    return <Skeleton />;
  }
  return children;
}
